.servicesBox>h3{
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
}

.servicesBox>p{
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
}

.servicesBox{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* .serviceInnerFlexBox{
    position: relative;
}
.serviceInnerFlexBox:after {
    content: '\A';
    position: absolute;
    width: 100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.6);
    opacity: 0;

}

.serviceInnerFlexBox:hover:after {
    opacity: 1;
}
  

.serviceInnerFlexBox:hover .text-on-image{
    opacity: 1;
}
  
.text-on-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:black;
    opacity: 0;
    z-index: 99;
}

.text-on-image button{
        color: #FFFFFF;
        border: 1px solid white;
        padding: 16px;
        font-weight: 700;
        line-height: 130%;
        border-radius: 50px;
        cursor: pointer;
} */


@media screen and (max-width: 1100px) {
    .serviceInnerFlex{
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    .serviceInnerFlexBox{
        width: 80vw;
        text-align: center;
       
    }   
}
@media screen and (max-width: 992px) {
    .serviceBox{
 
     width: 70vw;
     justify-content: center;
     align-items: center;
    }
    .serviceInnerFlex{
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
   
  }


