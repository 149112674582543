
.staff-compare-card-conatiner-mobile {
    display: none !important;
}

.service-desc-container-mobile {
    display: none !important;
}

.service-desc-container {
    display: flex !important;
}

.unique-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  } 

  .tech-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  
@media only screen and (max-width: 600px) {

    .service-desc-container-mobile {
        display: flex !important;
    }

    .service-desc-container {
        display: none !important;
    }
   
    .tab-content-text{
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .staff-compare-card-container {
        display: none !important;
    }

    .tab-buttons button {
        display: none !important;
    }
    
    .tab-buttons button.active {
        display: flex !important;
    }
        
    .staff-compare-card-conatiner-mobile {
        display: block !important;
    }

    .tab-change-btn {
        margin: 0px !important;
    }

    .tab-change-btn-icon {
        width: 45px !important;
        /* height: 30px !important; */
    }
    
    .tech-stack-text {
        width: 100% !important;
    }

    .staff-comparison-text {
        width: 100% !important;
    }

    .service-offering-text {
        width: 100% !important;
    }

    
    .how-it-works-text {
        width: 100% !important;
    }

    .unique-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
      } 

      .tech-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 32px;

      }
  }

  @media (max-width: 375px) {
    .unique-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
      } 

      .tech-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 32px;

      }

    .tech-stack-text {
        width: 100% !important;
    }

    .staff-comparison-text {
        width: 100% !important;
    }

    .service-offering-text {
        width: 100% !important;
    }

    .how-it-works-text {
        width: 100% !important;
    }

    .tab-change-btn-icon {
        width: 45px !important;
        /* height: 30px !important; */
    }
  }

  