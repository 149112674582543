@tailwind base;
@tailwind components;
@tailwind utilities;

/* body::-webkit-scrollbar{
    display: none;
}
.layout::-webkit-scrollbar{
    display: none;
}
 */
