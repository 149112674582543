@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');
*{
    font-family: 'Inter',sans-serif;
    font-style: normal;
}

.heading{
    line-height: 18.26vh;
}



.header_para{
    width: 50%;
    line-height: 28px;
}

.aboutUs_heading{
    font-weight: 900;
}

.text-decoration:hover{
    text-decoration: underline;
}

@media only screen and (max-width: 900px) {
    .heading{
        line-height: 80px;
    }

    .header_para{
        width: 98%;
    }
}

.about_us_para{
    font-family: 'Inter',sans-serif;
    font-style: normal;

    line-height: 140%;
    width: 80%;
}

.main_heading{
    line-height: 120px;
    font-weight: 900;
    font-style: normal;
    width: 80%;
    line-height: 120%;
}

.work_para{
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    width: 60%;
}

.customer_heading{
    font-style: normal;
    width: 60%;
    line-height: 120%;
}

.getInTouch_btn{

    background-image: linear-gradient(45deg,rgb(236, 51, 51),rgb(202,12,12), #A80808);

}