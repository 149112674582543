.EatsEzy , .bigfoot , .acai,.legate,.express-cart,.you-adme,.orizon-motorcycles,.osma{
    position: relative;
}

.EatsEzy:after ,.bigfoot:after,.acai:after,.legate:after , .express-cart:after,.you-adme:after,.orizon-motorcycles:after,.osma:after{
    content: '';
    position: absolute;
    width: 18%;
    height: 2px; 
    top: 50%;
    left: -20%;
}

.EatsEzy:after{
    background-color: #EF3340;
}

.bigfoot:after{
    background-color: #D74008;
}

.acai:after{
    background-color: #592D80;
}
.legate:after{
    background-color:#F47325 ;   
} 
.you-adme::after{
    background-color: #FDCF09;
}
.express-cart:after{
    background-color: #F7BE47;
}
.orizon-motorcycles:after{
    background-color: #F2994A;
}
.osma:after{
    background-color: #F79635;
}
