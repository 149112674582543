.swiper-pagination {
    position: static;
    margin-top: 35px;
}

.swiper-pagination-bullet {
    width: 40px;
    height: 40px;
    border: 7px solid #1D4ED8;
    background-color: white;
}

.swiper-button-prev,.swiper-button-next{
    display: none;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background-color: #1D4ED8;
}

.IndustriyIndi,.serviceInd{
    background-image: url("../../public/about-us-pattern.png");
}

@media (width<1010px) {
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        border-width: 4px;
    }
}

@media (width < 850px) {
    .our_scuucess_span{
        font-size: 20px;
        line-height: normal;
    }
    .our_success_p{
        font-size: 18px;
        line-height: normal;
    }
}
@media (width < 650px) {
    .our_scuucess_span{
        font-size: 18px;
    }
    .our_success_p{
        font-size: 16px;
    }
}
@media (width < 550px) {
    .our_scuucess_span{
        font-size: 14px;
    }
    .our_success_p{
        font-size: 12px;
    }
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-width: 3px;
    }
}
/* @media (width < 430px) {
    .our_scuucess_span{
        font-size: 10px;
    }
    .our_success_p{
        font-size: 9px;
    }
} */